

export default {
    name: '2-4',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{
            'a': {type: 'int', index:0}
        },
        readonly:[],
        nextIndex: 4,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[0],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 3,
    memScore: 12,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 0,
        links: [{ 
            color: 'pink',
            memoryName: 'main',
            index: 0
            }]
    }
    ],
    readonly: `
int main(void)
{
    int a;
    foo(randInt(), &a);
}
`
}