

export default {
    name: '2-7',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[1],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    },
    'bar': {
        name:'bar',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 0,
        values: []
    }
    },
    expScore: 4,
    memScore: 10,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 1,
        links: [{ 
            color: 'pink',
            memoryName: 'main',
            index: 1
        }]
    },{ 
        color: 'blue',
        memoryName: 'main',
        index: 1,
        value: 42
    }
    ],
    readonly: `
int main(void)
{
    int a = randInt();
    foo(a);
    bar(&a);
}
int foo(short int a)
{`
}