

export default {
    name: '3-2',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[2],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 5,
    memScore: 6,
    checks: [
    { 
        color: 'green-o',
        memoryName: 'foo',
        index: 2,
        links:[
            { 
                color: 'green-i',
                memoryName: 'foo',
                index: 0,
            },{ 
                color: 'green-i',
                memoryName: 'foo',
                index: 1,
            }
        ]
    }
    ],
    readonly: `
int main(void)
{
    unsigned char a = randInt() >> 15;
    unsigned char b = randInt() >> 15;
    unsigned char c = randInt();
    foo(a, b, c);
}
void foo(unsigned char a, unsigned char b, unsigned char c)
{
`
}