

export default {
    name: '2-5',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{
            'a': {type: 'int', index:0}
        },
        readonly:[],
        nextIndex: 4,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[0,1],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 6,
    memScore: 12,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 0,
        links: [{ 
            color: 'pink',
            memoryName: 'main',
            index: 0
            },{ 
            color: 'pink',
            memoryName: 'foo',
            index: 1
        }]
    },{ 
        color: 'blue',
        memoryName: 'main',
        index: 0,
        value: 42
    }
    ],
    readonly: `
int main(void)
{
    int a;
    foo(0, &a);
    foo(a, 0);
}
`
}