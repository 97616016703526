import { h } from 'hyperapp';

export default ({state}) => (
state.hideWelcome === false 
? (<div class="fancy-modal">
<h3>No Cookies for you</h3>
    <p class="message">
    This is a static webpage, 
      nothing is stored on a server, 
      your progress is saved on your browser in local storage.
    </p>

    <div class="options">
        <button 
            class="fancy-button" 
            onClick={(state) => {
                localStorage.setItem('hideWelcome', 'true')
                return {...state, hideWelcome: true}
            }}>
                Thank you!
        </button>
        <button 
            class="fancy-button" 
            onClick={(state) => {
                localStorage.setItem('hideWelcome', 'ads')
                return {...state, hideWelcome: 'ads'}
            }}>
                But I wanted pointers related Ads!!
        </button>
    </div>
</div>)
: (
    state.hideWelcome === 'ads' 
    ? <div class="container">
        <div class="fake-ad" style={{margin:'auto'}}><img src="./ad.jpg" /></div>
        <button onClick={(state) => {
                localStorage.setItem('hideWelcome', 'true')
                return {...state, hideWelcome: true}
            }}>
                Remove this ad!
        </button>
    </div>
    : null
  )
)