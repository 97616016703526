
import { h } from 'hyperapp';



const BaseComponent = ({text, color, dashed=6, strokeWidth=4, strokeColor='#333'}) => (
<svg width="37" height="37" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(0.5)">
    <path 
        stroke={strokeColor} 
        stroke-dasharray={dashed}
        fill={color} id="svg_3"
        d="m2.26499,30.785457l26.999999,-27.932793l26.999999,27.932793l-13.5,0l0,28.067201l-26.999997,0l0,-28.067201l-13.5,0z" 
        stroke-width={strokeWidth} />
    <text text-anchor="start" font-size="32" id="svg_4" y="52.453125" x="19.5" 
          fill-opacity="null" stroke-opacity="null" stroke-width="0" 
          stroke={strokeColor} fill={strokeColor}>
        {text}
    </text>
    </g>
</svg>)


export const ExpSuccess = () => <span  class='exp-challenge'>
    <BaseComponent text='C' color='#ffff99' dashed={0} strokeWidth={3} />
</span>
export const ExpFailed = ({disabled=false}) => <span  class='exp-challenge'>
    <BaseComponent text='C' color='#fff' strokeColor={disabled?'#b1b1b1':'#333'} />
</span>
export const MemSuccess = () => <span  class='mem-challenge'>
    <BaseComponent text='M' color='#ffff99' dashed={0} strokeWidth={3} />
</span>
export const MemFailed = ({disabled=false}) => <span  class='mem-challenge'>
    <BaseComponent text='M' color='#fff' strokeColor={disabled?'#b1b1b1':'#333'} />
</span>


export const isMemSuccess = (state, name) => {
    return state.results[name] && state.results[name].memScore <= state.levels[name].memScore
} 

export const isExpSuccess = (state, name) => {
    return state.results[name] && state.results[name].expScore <= state.levels[name].expScore
} 

export const memScore = (memories) => Object.values(memories).filter(m => m.name).reduce(
    (sum, mem) => sum + (mem.nextIndex || 0), 
    0); 
export const expScore = (memories) => Object.values(memories).filter(m => m.name).reduce(
    (sum, mem) => sum + (mem.expressionsCount || 0), 
    0); 