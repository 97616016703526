import { h, app } from 'hyperapp';
import parser from 'node-c-parser';
import cparse from './cparse';
import evaluate from './evaluate';
import moment from 'moment'
import cloneDeep from 'lodash.clonedeep';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/mode-c_cpp';

import 'moment/locale/fr';
import LevelComponent from './LevelComponent';
import {isLevelSuccess} from './LevelComponent';
import {selectLevel} from './LevelSelector';
import LevelSelector from './LevelSelector';
import Levels from './Levels';
import WelcomeDisclamer from './WelcomeDisclamer';

import {
  memScore,
  expScore
} from './ExpMemChallenge'

moment.locale('fr');





let initialState = {};
{
  let r = localStorage.getItem("results")
  if(r)
  {
    initialState.results = JSON.parse(r);
  }
  let code = localStorage.getItem("sourceCode")
  if(code)
  {
    initialState.sourceCode = JSON.parse(code);
  }
  switch(localStorage.getItem('hideWelcome', 'false'))
  {
    case 'true': initialState.hideWelcome = true; break;
    case 'ads': initialState.hideWelcome = 'ads'; break;
    default : initialState.hideWelcome = false; break;
  }
  
}

const pathname = location.search.slice(1) || null;

const defaultState = {
  levels: Levels, 
  currentLevel: null,
  sourceCode:{
    '1-1': ` 
int main(void)
{
  int a;
}
`
  },
  results:{},
  showInitialMemory: true
}

initialState = Object.assign({}, defaultState, initialState);
if(pathname && initialState.levels[pathname])
{
  initialState = selectLevel(pathname, false)(initialState);
}

function cloneMemory(memory)
{
  const newMemory = cloneDeep(memory)
  
  return newMemory;
}



function checkBlue(check, computedMemory)
{
  const {memoryName, index, value} = check;
  if(computedMemory[memoryName].values[index] === value)
  {
    return {
      type: 'blue',
      error: false
    }
  }
  return {
    type: 'blue',
    error: true
  }
}
function checkPink(check, computedMemory)
{
  const {memoryName, index, value, links} = check;
  const origin = computedMemory[memoryName].values[index];
  const error = !check.links.reduce((result, {memoryName, index, value}) => 
    result && computedMemory[memoryName].values[index] === origin, 
    true)
  return {
    type: 'red',
    error
  }
}
function checkGreen(check, computedMemory)
{
  const {memoryName, index, links} = check;

  const all = [check, ...check.links]
  const origin = all.filter(({color}) => color === 'green-i');
  const dest = all.filter(({color}) => color === 'green-o');
/*
  console.log({
    O: dest.map(
      ({memoryName, index}) => 
        computedMemory[memoryName].values[index]),
    I: origin.map(
      ({memoryName, index}) => 
        computedMemory[memoryName].values[index]),
  })*/
  
  const value = computedMemory[origin[0].memoryName].values[origin[0].index];
  const equals = origin.reduce(
    (result, {memoryName, index}) => 
      result && computedMemory[memoryName].values[index] === value, true)
  
  let error;
  if(equals)
  {
    error = !dest.reduce(
        (result, {memoryName, index}) => 
          result && computedMemory[memoryName].values[index] === value, true)
  } else 
  {
    error = !dest.reduce(
      (result, {memoryName, index}) => 
        result && computedMemory[memoryName].values[index] === 0, true)
  }
      
  return {
    type: 'green',
    error
  }
}
function checkSolution(level, computedMemory)
{
  
  const checks = level.checks.map(c => {
    switch(c.color)
    {
      case 'blue': return checkBlue(c, computedMemory);
      case 'pink': return checkPink(c, computedMemory);
      case 'green-o': 
      case 'green-i': return checkGreen(c, computedMemory);
    }
  });
  return {
    checks, 
    memScore: memScore(computedMemory),
    expScore: expScore(computedMemory)
  };
}

function fullCheck(state)
{
  const code = state.sourceCode[state.currentLevel.name];
  let result = [{type: 'compilation', error: true}]
  let computedMemory = {};
  try {
    const ast = cparse(code, {file:'file.c'});
    for(let i = 0; i < 100; i++)
    {
      computedMemory = cloneMemory(state.currentLevel.initialMemory);
      evaluate(ast, computedMemory); 
      result = checkSolution(state.currentLevel, computedMemory);
      if(!isLevelSuccess(result))
      {
        console.log('Failed in attempt', i);
        break;
      }
    }
    console.log({ast, computedMemory, result});
  } catch(err) { 
    console.log(err); 
    computedMemory.error = err.message; 
    if (err instanceof SyntaxError) {
      computedMemory.errorType = 'syntax'; 
    } else {
      computedMemory.errorType = 'runtime'; 
    }
  }
  const results = {
    ...state.results,
    [state.currentLevel.name]: result
  }
  localStorage.setItem('results', JSON.stringify(results));
  return {
    ...state,
    computedMemory,
    results
  };
}

function onCodeChange(state, code)
{
  const sourceCode = {
    ...state.sourceCode,
    [state.currentLevel.name]: code
  }
  let computedMemory = {};
  try {
    const ast = cparse(code, {file:'file.c'});
    computedMemory = cloneMemory(state.currentLevel.initialMemory);
    evaluate(ast, computedMemory); 
    console.log({ast, computedMemory});
  } catch(err) { 
    console.log(err); 
    computedMemory.error = err.message; 
    if (err instanceof SyntaxError) {
      computedMemory.errorType = 'syntax'; 
    } else {
      computedMemory.errorType = 'runtime'; 
    }
  }
  localStorage.setItem('sourceCode', JSON.stringify(sourceCode));

  let result = state.results[state.currentLevel.name];
  let results = state.results;
  if(!isLevelSuccess(result))
  {
    results = {
      ...state.results,
      [state.currentLevel.name]: []
    }
  }
  return {
    ...state,
    sourceCode,
    computedMemory,
    results
  };
}

function cellLocation(memoryArea, index)
{
  const el = document.getElementById('cell-'+memoryArea+'-'+index);
  
  return {x: (index%4) * 58, y: Math.floor(index/4) * 58};

  if(!el) return {x:0, y:0};

  console.log('el', memoryArea, index);
  console.log(el.offsetLeft);
  console.log(el.offsetTop);
  return {x: el.offsetLeft, y:el.offsetTop};
}
function pointeeLocation(v)
{
  return cellLocation(v.memory.name, v.index);
}

function svgPath(p1, p2)
{
  p1.x+=53;
  p1.y+=42;
  const midx = (p1.x+p2.x)/2;
  const midy = (p1.y+p2.y)/2;
  return <path d={"M"+p1.x+" "+p1.y+" Q "+
              midx+" "+(midy+60)+", "+
              p2.x+" "+p2.y} stroke="black" fill="transparent"/>
}



const Main = (state) => <div>
  {console.log({state})}
  {state.currentLevel && <div class="row header-level-nav">
    <div class="container">
      <div class="row header">
          <span class="current-level">{state.currentLevel.name}</span>
          <button 
            class="hand lined thin" 
            onClick={(state) => {
                if(history) 
                {
                  history.back();
                  return state;
                }
                return {...state, currentLevel:null}
              }
            }
            >
            Go Back
          </button>
          <button 
            class="hand lined thin" 
            onClick={fullCheck}
            >
              Verify!
          </button>{
            isLevelSuccess(state.results[state.currentLevel.name])
            ? <span class="you-did-it">Bravo! You did it!!</span>
            : (
              state.results[state.currentLevel.name] 
              && state.results[state.currentLevel.name].length > 0
              && <span class="you-did-not-did-it">
                Failed
              </span>
            )}
      </div>
    </div>
  </div>
  }
  <WelcomeDisclamer state={state} />
  <div class="container">
    {!state.currentLevel && <LevelSelector state={state} />}
    {state.currentLevel &&
     <LevelComponent 
     showInitialMemory={state.showInitialMemory}
      result={state.results[state.currentLevel.name]}
      code={state.sourceCode[state.currentLevel.name]}
      level={state.currentLevel}
      computedMemory={state.computedMemory}
      showHelp={state.showHelp} />
    }
  </div>
</div>


const dispatch = app({
  node: document.getElementById("app"),
  init: initialState,
  view: (state) => Main(state),
});

window.onpopstate = function(event) {
  if(!event.state)
  {
    dispatch((state) => ({...state, currentLevel:null}));
  }
  else 
  {
    dispatch(selectLevel(event.state.level, false));
  }
};

customElements.define('custom-editor',
  class extends HTMLElement {
    constructor() {
      super();
      this.readonlyLines = this.getAttribute('readonly-lines') ;
      this.markerIds = [];
      this.lockUpdate = false;
  }
  static get observedAttributes() { return ['readonly-lines']; }
  attributeChangedCallback(name, oldValue, newValue) {
    console.log('Custom square element attributes changed.');
    this.readonlyLines = this.getAttribute('readonly-lines');
    this.forceUpdate();
  }
  forceUpdate()
  {
    if(this.editor)
    {
      this.editor.session.insert(0, '');
      this.updateMarker();
    }
  }
  updateMarker()
  {
    const expectedLines = this.readonlyLines ? this.readonlyLines.split('\n') : [];
    
    this.markerIds.forEach(m => this.editor.session.removeMarker(m));
    this.markerIds = []
    this.markerIds.push(this.editor.session.addMarker(new ace.Range(0, 0, expectedLines.length - 1, 1), "readonly", "fullLine"));

  }
  onValueUpdated(event)
  {
    if(this.lockUpdate) return;
    //console.log({event})
    //console.log({editorValue: this.editor.getValue()})

    const expectedLines = this.readonlyLines ? this.readonlyLines.split('\n') : [];
    
    let ok = true;
    for(let l = 0; l < expectedLines.length; l++)
    {
      if(this.editor.session.getLine(l) !== expectedLines[l])
      { 
        ok = false;
        break;
      } 
    }

    if(!ok)
    {
      this.lockUpdate = true;
      const doc = this.editor.session.getDocument()
      let end = expectedLines.length - 1;
      let start = end;
      if(event.action === 'remove')
      {
        start = Math.min(end, Math.max(0, event.start.row));
        end = Math.min(end, event.end.row);
        //console.log({start, end})
      }
      doc.removeFullLines(0, expectedLines.length - 1 - (end-start));
      doc.insertFullLines(0, expectedLines);
      //TODO: reinsert other lines
      this.editor.session.getUndoManager().reset();
      this.updateMarker();
      this.lockUpdate = false;
    }
    let code = this.editor.getValue();
    dispatch((state) => onCodeChange(state, code))
  }

  connectedCallback() {
    this.initialValue = this.getAttribute('value');
    this.innerHTML = '<pre id="editor"></pre>'; 
    this.editor = ace.edit("editor");
    this.editor.setTheme("ace/theme/xcode");
    this.editor.session.setMode("ace/mode/c_cpp");
    this.editor.getSession().on('change', 
      (event) => this.onValueUpdated(event)
    );
    this.editor.setValue(this.initialValue, 1);
    this.forceUpdate();
  }
});