

export default {
    name: '3-4',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[0,4],
        nextIndex: 0,
        minValuesCount: 0,
        values: []
    },
    'bar': {
        name:'bar',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 6,
    memScore: 18,
    checks: [
    { 
        color: 'green-o',
        memoryName: 'bar',
        index: 0,
        links:[
            { 
                color: 'green-i',
                memoryName: 'main',
                index: 0,
            },{ 
                color: 'green-i',
                memoryName: 'main',
                index: 4,
            }
        ]
    }
    ],
    readonly: `
int main(void)
{
    int a = randInt() >> 15;
    int b = randInt() >> 15;
    foo(a, &b);
    bar(a);
}`
}
