

export default {
    name: '2-3',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[2],
        nextIndex: 0,
        minValuesCount: 12,
        values: [
        1,
        2,
        52,
        3,
        3
        ]
    }
    },
    expScore: 2,
    memScore: 5,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 2,
        links: [{ 
            color: 'pink',
            memoryName: 'foo',
            index: 0
            },{ 
            color: 'pink',
            memoryName: 'foo',
            index: 4
        }]
    }
    ],
    readonly: `
int main(void)
{
    foo(randInt(), randInt());
}
`
}