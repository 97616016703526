import { h } from 'hyperapp';

import Board from './Board';
import Help from './Help';

import {
  memScore,
  expScore,
  ExpSuccess,
  ExpFailed,
  MemSuccess,
  MemFailed
} from './ExpMemChallenge'

export function isLevelSuccess(result)
{
  return result 
  && result.checks
  && result.checks.length 
  && result.checks.reduce((acc, r) => acc && r && !r.error, true);
}

export default ({result, code, level, computedMemory, showHelp, showInitialMemory}) => (
<div>
    {showHelp && <Help levelName={level.name} />}
    <div class="row">
      <div class="col editor-wrapper">
        <custom-editor 
            readonly-lines={level.readonly}
            value={code || '\n\n'}>
        </custom-editor>
      </div>
      <div class="col">
        <div class="boards-wrapper">
            {computedMemory && (computedMemory.error
            ? [
                <h2>A {computedMemory.errorType} error occured</h2>,
                <div class="computation-error">{computedMemory.error}</div>,
                computedMemory.errorType !== 'syntax'
                ? [
                    <h4>Below is the state of the memory before it occured</h4>,
                    <Board level={level} memory={computedMemory} />
                ]
                : [
                    <h4>Below is the initial state of the memory</h4>,
                    <Board level={level} memory={level.initialMemory} />
                ]
            ]
            : <Board level={level} memory={computedMemory} />
            )}
            
            {
            computedMemory && computedMemory.main &&
              [
                <h4 class="bonus-challenges">Bonus Challenges:</h4>,
                <div>
                  {
                    expScore(computedMemory) <= level.expScore
                    ? <ExpSuccess />
                    : <ExpFailed />
                  }<span>Complexity: </span>
                  {expScore(computedMemory)} / {level.expScore}
                </div>,
                <div>
                  {
                    memScore(computedMemory) <= level.memScore
                    ? <MemSuccess />
                    : <MemFailed />
                  }<span>Memory Usage: </span>
                  {memScore(computedMemory)} / {level.memScore}
                </div>
              ]
            }
        </div>
      </div>
    </div>
</div>
)