

export default {
    name: '3-1',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 4,
    memScore: 5,
    checks: [
    { 
        color: 'green-o',
        memoryName: 'foo',
        index: 2,
        links:[
            { 
                color: 'green-i',
                memoryName: 'foo',
                index: 0,
            },{ 
                color: 'green-i',
                memoryName: 'foo',
                index: 1,
            }
        ]
    }
    ],
    readonly: `
int main(void)
{
    unsigned char a = randInt() >> 15;
    unsigned char b = randInt() >> 15;
    foo(a, b);
}
`
}