

export default {
    name: '2-2',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[0],
        nextIndex: 0,
        minValuesCount: 12,
        values: [
        1,
        2,
        52,
        3,
        3
        ]
    }
    },
    expScore: 2,
    memScore: 6,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 0,
        links: [{ 
        color: 'pink',
        memoryName: 'foo',
        index: 4
        },{ 
        color: 'pink',
        memoryName: 'foo',
        index: 5
        }]
    }
    ],
    readonly: `
int main(void)
{
    // randInt() returns a random unsigned short int
    foo(randInt());
}
`
}