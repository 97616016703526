
export default {
    name: '1-2',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{
        'a': {type:'int', index:0}
        },
        nextIndex: 4,
        minValuesCount: 4,
        values: [
        0,0,0,0
        ]
    }
    },
    expScore: 2,
    memScore: 4,
    checks: [
    { 
        color: 'blue',
        memoryName: 'main',
        index: 1,
        value: 42
    }
    ],
    readonly: `
int main(void)
{
    int a;`
}