

export default {
    name: '2-6',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{},
        readonly:[],
        nextIndex: 0,
        minValuesCount: 0,
        values: []
    },
    'foo': {
        name:'foo',
        variables:{},
        readonly:[1],
        nextIndex: 0,
        minValuesCount: 4,
        values: []
    }
    },
    expScore: 2,
    memScore: 2,
    checks: [
    { 
        color: 'pink',
        memoryName: 'foo',
        index: 1,
        links: [{ 
            color: 'pink',
            memoryName: 'foo',
            index: 0
        }]
    }
    ],
    readonly: `
int main(void)
{
    foo(randInt());
}
int foo(unsigned short int a)
{`
}