import { h } from 'hyperapp';
import {isLevelSuccess} from './LevelComponent';
import { saveAs } from 'file-saver';

import {
  isExpSuccess,
  isMemSuccess,
  MemFailed,
  MemSuccess,
  ExpFailed,
  ExpSuccess
} from './ExpMemChallenge';


export function selectLevel(level, pushState = true){
    return (state) => {
      if(history && pushState) 
      {
        history.pushState({level}, `GoP ${level}`, '?'+level);
      }
      
      return {
        ...state,
        currentLevel: state.levels[level],
        showHelp: true
      }
    }
  }

function listMajorLevels(levels)
{
    const majorList = [];
    let major = 1;
    while(levels[major+'-1']) majorList.push(major++);
    return majorList;
}
function listMinorLevels(levels, major)
{
    const minorList = [];
    let minor = 1;
    while(levels[major+'-'+minor]) minorList.push(major+'-'+(minor++));
    return minorList;
}

export default ({state}) => {
    let previousIsSucces = true;
    let previousMajorLevel = '1';
    return <div>
    <h1>The Game of Pointers</h1>   
    <div class='level-selector-wrapper'>
    {listMajorLevels(state.levels).map((major, i) => {

        return <div key={i} class="row">
            {listMinorLevels(state.levels, major)
                .map((name, j) => {
                    const success = isLevelSuccess(state.results[name]);
                    const ready = previousIsSucces;
                    previousIsSucces = success;
                    const disabled = (!ready && !success)
                    return (
                        <button 
                            disabled={disabled}
                            key={i} 
                            class={'level-button hand'
                            +(success
                                ?' lined thin'
                                :(ready
                                    ? ' dashed thick'
                                    : ' dotted thin disabled')
                            )}
                            onClick={selectLevel(name)}
                            >{name}
                              {isMemSuccess(state, name) 
                              ? <MemSuccess />
                              : <MemFailed disabled={disabled} />}
                              {isExpSuccess(state, name) 
                              ? <ExpSuccess />
                              : <ExpFailed disabled={disabled} />}
                              
                            </button>
                    )

                })}
        </div>    
    }
    )}
  </div>
  <button class='fancy-button' onclick={(state) => {
    var blob = new Blob([JSON.stringify({
      results: state.results,
      sourceCode: state.sourceCode
    })], {type: "text/json;charset=utf-8"});
    saveAs(blob, "Game of pointers.save.json");
    return state;
  }}>Save progression</button>
  <br/>
  <br/>
  {state.loaded
  ? <a onclick={state => ({...state, loaded:false})} class="fancy-button">
    Done!
  </a>
  : <a class="fancy-button">
    Load Progression: <input oninput={(state, event) => {
      var file = event.target.files[0];
      if (!file) {
        return state;
      }
      // read file asynchronously, dispatch
      // new state when its done
      return [state, [(dispatch) => {
        var reader = new FileReader();
        reader.onload = function(e) {
          var contents = e.target.result;
          try{
            const progression = JSON.parse(contents);
            
            localStorage.setItem('results', 
              JSON.stringify(progression.results));
            localStorage.setItem('sourceCode', 
              JSON.stringify(progression.sourceCode));
            dispatch(state=>({
              ...state,
              ...progression,
              loaded:true
            }));
          }
          catch(e)
          {
            // ignore error
            // let the user retry
            alert('there was an error, please retry!');
          }
        };
        reader.readAsText(file);
      }, null]]
    }
  } type="file" id="file-input" />
  </a>}
  </div>
}