
export default {
    name: '1-3',
    initialMemory:{ 
    'main': {
        name:'main',
        variables:{
        },
        nextIndex: 0,
        minValuesCount: 5,
        values: []
    }
    },
    expScore: 2,
    memScore: 5,
    checks: [
    { 
        color: 'blue',
        memoryName: 'main',
        index: 0,
        value: 42
    },
    { 
        color: 'blue',
        memoryName: 'main',
        index: 1,
        value: 42
    },
    { 
        color: 'blue',
        memoryName: 'main',
        index: 2,
        value: 42
    },
    { 
        color: 'blue',
        memoryName: 'main',
        index: 3,
        value: 42
    },
    { 
        color: 'blue',
        memoryName: 'main',
        index: 4,
        value: 42
    }
    ],
    readonly: null
}