import { h } from 'hyperapp';

function getCellNameNode(memory, index)
{
  const found = Object.entries(memory.variables)
          .find(([key, value]) => value.index === index)
  if(!found) return null;
  return <span class="memory-cell-name">
      {found[0]} 
  </span>; // found[0] is the name
}

function isReadOnly(memory, index)
{
    return memory.readonly && memory.readonly.indexOf(index) >= 0;
}

function getCellClass(level, memory, index)
{
    let classes = 
        level.checks
        .filter(c => c.memoryName === memory.name)
        .filter(c => c.index === index).map(c => c.color);
    
    level.checks
        .filter(c => c.links)
        .forEach(c => c.links
                    .filter(c => c.memoryName === memory.name)
                    .filter(c => c.index === index)
                    .forEach(c => classes.push(c.color)));
    
    if(isReadOnly(memory, index))
    {
        classes.push('readonly');
    }
    
    return classes;
}

function valueToString(v) {
  return v;
}

function isPointer(v)
{
  return typeof v === "object"//v.type.endsWith('*');
}

function completeValueArray(memory)
{
    if(!memory.minValuesCount) return memory.values;

    const missingValues = memory.minValuesCount - memory.values.length;
    if(missingValues <= 0)
    {
        return memory.values;
    }
    let missingArray = [...Array(missingValues).keys()].map(i => Math.ceil(Math.random()*256))
    return [...memory.values, ...missingArray];
}

export default ({level, memory}) => (
    memory && 
    (Object
        .values(memory)
        .map((localMemory, i) => 
            (localMemory.values instanceof Array) 
            && (localMemory.values.length > 0 || localMemory.minValuesCount > 0) 
            && (
                <div key={i} class="board-wrapper">
                <h2>function <strong>{localMemory.name}</strong></h2>
                <div class="board" id={"board-"+localMemory.name}>
                    {completeValueArray(localMemory).map(
                    (v, i) => <div class={["memory-cell", ...getCellClass(level, localMemory, i)].join(' ')} id={"cell-"+'main'+'-'+i} key={i}>
                        <span class="memory-cell-value">{valueToString(v)}</span>
                        {getCellNameNode(localMemory, i)}
                        {isReadOnly(localMemory, i) && <span class="readonly-indicator">r</span>}
                    </div>
                    )}
                </div>
                
                <svg xmlns="http://www.w3.org/2000/svg">
                    {localMemory.values
                    .map((v , i) => ({ v,i }))
                    .filter(o => isPointer(o.v))
                    .map(
                        o => svgPath(cellLocation('main', o.i), pointeeLocation(o.v))
                    )}
                    {}
                </svg>
                <div class="clear"></div>
                </div>
            )
        )
    )
)