
const _sizeOf = {
    'int' : 4,
    'unsigned int' : 4,
    'signed int' : 4,
    'short int': 2,
    'short unsigned int': 2,
    'short signed int': 2,
    'char': 1,
    'unsigned char': 1,
    'signed char': 1,
    'POINTER': 4
};

function sizeOf(type)
{
    if(typeIsArray(type)) 
        return sizeOf(arrayElementType(type)) 
                * arrayLength(type);
    if(typeIsPointer(type)) return _sizeOf['POINTER'];
    return _sizeOf[type] || 1;
}
function arrayLength(type)
{
    const m = type.match(/\[([0-9]+)\]/);
    return parseInt(m[1]);
}
function arrayElementType(type)
{
    const m = type.match(/([^\[]+)\[([0-9]+)\]/);
    return m[1];
}
function getAlignment(type)
{
    if(typeIsArray(type)) 
        return sizeOf(arrayElementType(type))
    return sizeOf(type);
}
function typeIsPointer(type)
{
    return type.endsWith('*');
}
function typeIsArray(type)
{
    return type.endsWith(']');
}
function isPointer(value)
{
    return typeIsPointer(value.type)
}

function derefType(type)
{
    return type.slice(0, type.length-1)
}

function eTrue(value)
{
    return !(
        value.values[0] === 0
        && value.values[1] === 0
        && value.values[2] === 0
        && value.values[3] === 0
    )
}


function littleEndianConstant(value) 
{
    if(isNaN(value)) { throw Error("value is not a number "+value); }
    const type = value < 0 ? 'type' : 'unsigned int';
    while(value < 0) {
        value += Math.pow(2,32);
    }
    value = value % Math.pow(2,32);
    return {
        type,
        values: [value%256, Math.floor(value/Math.pow(2,8))%256, Math.floor(value/Math.pow(2,16))%256, Math.floor(value/Math.pow(2,24))%256]
    }
    /*
    if(value < 256) return [value];
    return [...littleEndianConstant(value%256), 
        ...littleEndianConstant(Math.floor(value/256))]
    */
}
function littleEndianToValue(obj) 
{
    const values = [...obj.values] // copy the array
    while(values.length < 4) values.push(0);

    const v = values[0] +
            (values[1] * Math.pow(2, 8))+
            (values[2] * Math.pow(2, 16))+
            (values[3] * Math.pow(2, 24));

    if(!obj.type.match(/unsigned /))
    {
        if(v >= Math.pow(2,31))
        {
            return v - Math.pow(2,32);
        }
    }
    return v;
}

function getTypeFromDefType(context, defType)
{
    if(defType.type === "PointerType")
    {
        return getTypeFromDefType(context, defType.target)+'*';
    }
    if(defType.type === "ArrayType")
    {
        if(!defType.length)
        {
            throw Error('Array declaration should include its size');
        }
        return getTypeFromDefType(context, defType.target)+'['+
            littleEndianToValue(eValue(context, defType.length))
            +']';
    }
    return defType.modifier.sort().join(' ')+
        (defType.modifier.length ? ' ' : '')+
        defType.name;
}
const MAGIC_VALUE_POINTER_2 = 255;
const MAGIC_VALUE_POINTER_3 = 0;

function derefPointer(localMemory, pointer)
{
    if(!isPointer(pointer))
    {
        throw Error('trying to dereference a value that is not a pointer')
    }
    if(pointer.values[2] !== MAGIC_VALUE_POINTER_2 
        || pointer.values[3] !== MAGIC_VALUE_POINTER_3)
    {
        throw Error("Segmentation Fault")
    }
    const targetMemoryIndex = pointer.values[1];
    const targetMemory = localMemory.memoryArray[targetMemoryIndex];
    const targetType = derefType(pointer.type);
    if(!targetMemory) throw Error("Segmentation Fault")
    return {
        type: targetType,
        values: targetMemory.values.slice(
            pointer.values[0], 
            pointer.values[0] + sizeOf(targetType)
        ),
        memory: targetMemory,
        index: pointer.values[0]
    }
}

function isANumber(value)
{
    return !!value.type.match(/(int|short|char)$/)
}

function eValue(context, value)
{
    const {memory} = context;
    if(value.type === "Literal")
    {
        if(value.value instanceof Array)
        {
            throw Error('notation {...} is used only to initialize arrays and structures, line '+value.pos.line)
        }
        return littleEndianConstant(value.value);
    }else
    if(value.type === "Identifier")
    {
        const id = value.value;
        const vari = memory.variables[id];
        if(vari === undefined)
        {
            throw Error("Unknown identifier '"+id+"'");
        }
        if(typeIsArray(vari.type))
        {
            return {
                type: arrayElementType(vari.type)+'*',
                values: [
                    vari.index, 
                    memory.index, 
                    MAGIC_VALUE_POINTER_2, 
                    MAGIC_VALUE_POINTER_3]
            }
        }
        return {
            type: vari.type,
            values: memory.values.slice(
                vari.index, 
                vari.index + sizeOf(vari.type)
            )
        }
    }
    else if(value.type === "PrefixExpression")
    {
        if(value.operator === "&")
        {
            if(value.value.type !== "Identifier") {
                throw Error("Expect Identifier after &")
            }
            const ident = value.value.value;
            const index = memory.variables[ident].index;
            if(index === undefined) {
                throw Error("Unknown identifier '"+ident+"'");
            }
            return {
                type: memory.variables[ident].type+'*',
                values: [
                    index, 
                    memory.index, 
                    MAGIC_VALUE_POINTER_2, 
                    MAGIC_VALUE_POINTER_3]
            }
        } else if(value.operator === "*")
        {
            const targetValue = derefPointer(memory, eValue(context, value.value));
            if(!targetValue)
            {
                throw Error("Can only dereference a pointer");
            }
            return targetValue;
        } 
        else if(value.operator === '-')
        {
            return eMinus(littleEndianConstant(0), eValue(context, value.value));
        } 
        else if(value.operator === '+')
        {
            return eValue(context, value.value);
        }
        else if(value.operator === '~')
        {
            const number = eValue(context, value.value);
            return littleEndianConstant(
                ~ littleEndianToValue(number)
            )
        }
        
    } else if(value.type === "IndexExpression") 
    {
        const index = eValue(context, value.index);
        let lvalue = eValue(context, value.value);
        lvalue = ePlus(lvalue, index);
        lvalue = derefPointer(context.memory, lvalue);
        if(!lvalue)
        {
            throw Error("A pointer is expected when using bracket operator");
        }
        return lvalue;
    } else if(value.type === "BinaryExpression") {
        if(value.operator === "=")
        {
            return eAffectation(context, value);
        } else
        if(value.operator === "==")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) === littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === "!=")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) !== littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === ">=")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) >= littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === ">")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) > littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === "<=")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) <= littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === "<")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) < littleEndianToValue(rightNumber)
                ? 1 : 0
            )
        } else
        if(value.operator === "&")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) & littleEndianToValue(rightNumber)
            )
        } else
        if(value.operator === "|")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) | littleEndianToValue(rightNumber)
            )
        } else
        if(value.operator === "^")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            return littleEndianConstant(
                littleEndianToValue(leftNumber) ^ littleEndianToValue(rightNumber)
            )
        } else
        if(value.operator === "+")
        {
            return ePlus(
                eValue(context, value.left), 
                eValue(context, value.right));
        } else
        if(value.operator === "-")
        {
            return eMinus(
                eValue(context, value.left), 
                eValue(context, value.right));
        } else
        if(value.operator === "%")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            if(!isANumber(leftNumber) || !isANumber(rightNumber))
            {
                throw Error("operator % is only possible with numbers")
            }
            console.log('%', leftNumber, littleEndianToValue(leftNumber))
            return littleEndianConstant(
                    littleEndianToValue(leftNumber) 
                    % littleEndianToValue(rightNumber)
                );
        } else
        if(value.operator === "&&")
        {
            const leftVal = eValue(context, value.left);
            const rightVal = eValue(context, value.right);
            if(eTrue(leftVal) && eTrue(rightVal))
            {
                return littleEndianConstant(1);
            }
            return littleEndianConstant(0);
        } else
        if(value.operator === "||")
        {
            const leftVal = eValue(context, value.left);
            const rightVal = eValue(context, value.right);
            if(isTrue(leftVal) || isTrue(rightVal))
            {
                return littleEndianConstant(1);
            }
            return littleEndianConstant(0);
        } else
        if(value.operator === "<<")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            if(!isANumber(leftNumber) || !isANumber(rightNumber))
            {
                throw Error("operator << is only possible with numbers")
            }
            return littleEndianConstant(
                    littleEndianToValue(leftNumber) 
                    * Math.pow(2, littleEndianToValue(rightNumber))
                );
        } else
        if(value.operator === ">>")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            if(!isANumber(leftNumber) || !isANumber(rightNumber))
            {
                throw Error("operator >> is only possible with numbers")
            }
            return littleEndianConstant(
                    Math.floor(littleEndianToValue(leftNumber) 
                    / Math.pow(2, littleEndianToValue(rightNumber)))
                );
        } else
        if(value.operator === "*")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            if(!isANumber(leftNumber) || !isANumber(rightNumber))
            {
                throw Error("Multiplication is only possible with numbers")
            }
            return littleEndianConstant(
                    littleEndianToValue(leftNumber) 
                    * littleEndianToValue(rightNumber)
                );
        } else
        if(value.operator === "/")
        {
            const leftNumber = eValue(context, value.left);
            const rightNumber = eValue(context, value.right);
            if(!isANumber(leftNumber) || !isANumber(rightNumber))
            {
                throw Error("Multiplication is only possible with numbers")
            }
            console.log({leftNumber, rightNumber})
            console.log(littleEndianToValue(leftNumber), littleEndianToValue(rightNumber))
            return littleEndianConstant(
                    Math.floor(littleEndianToValue(leftNumber) 
                    / littleEndianToValue(rightNumber))
                );
        }
    }
    else if(value.type === "CastExpression") {
        console.log('CastExpression', value);
        const castedValue = eValue(context, value.value);
        if(isPointer(castedValue))
        {
            if(value.targetType.type === 'PointerType')
            {
                let newType = getTypeFromDefType(context, value.targetType);                
                return {
                    ...castedValue,
                    type:newType
                };
            }
            throw Error("only a pointer can be casted to pointer")
        }
        console.warn('type value cast! not implemented');
        return castedValue;
    } 
    else if(value.type === "CallExpression") {
        return context.globalCall(
            value.base.value,
            value.arguments.filter(a => a !== undefined)
                           .map(arg => eValue(context, arg))
        );
    }
    else if(value.type && value.values instanceof Array)
    {
        // value already evaluated
        return value;
    }

    console.log('unknown value type', value)
    throw Error('unknown value type '+value.type+', line '+value.pos.line)

}

function ePlus(left, right)
{
    if(!isPointer(left) && !isPointer(right))
    {
        return littleEndianConstant(
                littleEndianToValue(left) 
                + littleEndianToValue(right)
            );
    }
    if(isPointer(left) && isPointer(right))
    {
        throw Error("cannot add two pointers");
    }
    if(isPointer(right)){
        return ePlus(right, left);
    }
    let newVal = (left.values[0] + littleEndianToValue(right)*sizeOf(derefType(left.type)))%256;
    while(newVal<0) newVal+=256;
    return {
        type: left.type,
        values: [
            newVal,
            left.values[1],
            left.values[2],
            left.values[3]
        ]
    }
}


function eMinus(left, right)
{
    if(!isPointer(left) && !isPointer(right))
    {
        console.log({left, right})
        console.log(littleEndianToValue(left),littleEndianToValue(right))
        return littleEndianConstant(
                littleEndianToValue(left) 
                - littleEndianToValue(right)
            );
    }
    if(isPointer(left) && isPointer(right))
    {
        if(left.type !== right.type)
        {
            Error("subtracting pointer can be done only between pointers with same types")
        }
        if(left.values[1] !== right.values[1])
        {
            Error("subtracting pointer can be done only between pointers in same memory space")
        }
        return {
            type: left.type,
            values: [
                (left.values[0] - right.values[0] + 256)%256,
                left.values[1],
                (left.values[2] - right.values[2] + 256)%256,
                (left.values[3] - right.values[3] + 256)%256
            ]
        }
    }
    if(isPointer(right)){
        throw Error("cannot substract pointers to a number")
    }
    let newVal = (left.values[0] - littleEndianToValue(right)*sizeOf(derefType(left.type)))%256;
    while(newVal < 0) newVal+=256;
    return {
        type: left.type,
        values: [
            newVal,
            left.values[1],
            left.values[2],
            left.values[3]
        ]
    }
}


function affectation(targetMemory, targetIdx, targetType, value, originContext, fullRights=false)
{
    //console.log(JSON.parse(JSON.stringify({targetMemory, targetIdx, targetType, value, localMemory})))
    let valuesToInsert = eValue(originContext, value).values;
    if(!Array.isArray(valuesToInsert))
    {
        Error("unknown error during affectation, rvalue does not contains values")
    }
    for(let i = 0; i < sizeOf(targetType); i++)
    {
        if(!fullRights && targetMemory.readonly && targetMemory.readonly.indexOf(targetIdx + i) >= 0)
        {
            throw Error("Write access to read only section");
        }
        targetMemory.values[targetIdx + i] = valuesToInsert[i] || 0;    
    }
}
function affectationName(context, target, value, fullRights=false)
{
    let idx = context.memory.variables[target].index;
    let type = context.memory.variables[target].type;
    if(typeIsArray(type))
    {
        throw Error(`array '${target}' is not assignable`+(value.pos ? `line ${value.pos.line}`:''));
    }
    if(idx === undefined)
    {
        throw Error("Unknown identifier '"+target+"'");
    }
    affectation(context.memory, idx, type, value, context, fullRights);
}
function arrayAffectation(context, target, value)
{
    let idx = context.memory.variables[target].index;
    let type = context.memory.variables[target].type;
    if(idx === undefined)
    {
        throw Error("Unknown identifier '"+target+"'");
    }
    const len = arrayLength(type);
    const elType = arrayElementType(type)
    const size = sizeOf(elType);
    const zeroVale = littleEndianConstant(0)
    for(let i = 0; i < len; i++)
    {
        if(i < value.value.length){
            affectation(context.memory, idx + i * size, elType, 
                value.value[i] || zeroVale, context, true);
        } else {
            affectation(context.memory, idx + i * size, elType, 
                zeroVale, context, true);
        }
    }
}
function eAffectation(context, exp)
{
    if(exp.left.type === "PrefixExpression"
        && exp.left.operator === "*")
    {
        const lvalue = derefPointer(context.memory, eValue(context, exp.left.value));
        if(!lvalue)
        {
            throw Error("A pointer is expected after operator *");
        }
        affectation(lvalue.memory, lvalue.index, lvalue.type, exp.right, context)
    }
    else if(exp.left.type === "Identifier")
    {
        affectationName(context, exp.left.value, exp.right);
    } 
    else if(exp.left.type === "IndexExpression") 
    {
        const lexp = exp.left
        const index = eValue(context, lexp.index);
        let lvalue = eValue(context, lexp.value);
        lvalue = ePlus(lvalue, index);
        lvalue = derefPointer(context.memory, lvalue);
        if(!lvalue)
        {
            throw Error("A pointer is expected when using bracket operator");
        }
        affectation(lvalue.memory, lvalue.index, lvalue.type, exp.right, context)
    }
    else {
        console.log('unknown affectation lvalue', exp.left);
        throw Error('unknown affectation lvalue, line '+exp.pos.line);
    }
    return eValue(context, exp.right);
}

function variableDeclaration(context, exp)
{
    const {memory} = context;
    if(memory.variables[exp.name] !== undefined)
    {
        return; //already assigned a memory location
    }

    const type = getTypeFromDefType(context, exp.defType);
    let size = sizeOf(type);
    if(size === 0)
    {
        throw Error(`Type "${type}" does not exists (at least not in this game)`)
    }
    let alignment = getAlignment(type);

    if(memory.nextIndex % alignment !== 0)
    {   
        // align memory
        memory.nextIndex += alignment - (memory.nextIndex % alignment);
    }
    memory.variables[exp.name] = {
        type,
        index: memory.nextIndex
    };
    memory.nextIndex += size
    for(let i = memory.values.length; i < memory.nextIndex; i++)
    {
        memory.values.push(Math.floor(Math.random()*256));
    }
}

function eBody(context, args=[])
{
    const {ast, memory} = context;
    if(ast.type === "FunctionDeclaration")
    {
        if(args.length !== ast.arguments.length)
        {
            throw Error('Some arguments are missing');
        }
        ast.arguments.forEach((argDeclaration, i) => {
            variableDeclaration(context, argDeclaration);
            affectationName(context, argDeclaration.name, args[i], true)
        });
    }
    for(const exp of ast.body)
    {
        memory.expressionsCount++;
        if(exp.type === "VariableDeclaration")
        {
            const name = exp.name;
            if(memory.variables[name] === undefined)
            {
                variableDeclaration(context, exp);
            }
            if(exp.value !== undefined)
            {
                if(typeIsArray(memory.variables[name].type))
                {
                    arrayAffectation(context, name, exp.value);
                }
                else
                {
                    affectationName(context, name, exp.value, true);
                }
            }
        } else
        if(exp.type === "ExpressionStatement" && exp.expression.operator === "=")
        {
            eAffectation(context, exp.expression)
        } 
        else if(exp.type === "ExpressionStatement" && exp.expression.type === "CallExpression")
        {
            if(exp.expression.base.type !== "Identifier")
            {
                throw Error("Only function with identifier can be called");
            }
            context.globalCall(
                exp.expression.base.value,
                exp.expression.arguments
                    .filter(arg => arg !== undefined)
                    .map(arg => eValue(context, arg))
            );
        } else if(exp.type === "IfStatement")
        {
            const condition = eValue(context, exp.condition);
            if(eTrue(condition))
            {
                eBody({...context, ast:exp});
            } else {
                if(exp.else)
                {
                    eBody({...context, ast:{body: exp.else}});
                }
            }
        }
        else {
            console.log('unknown expression', exp);
            throw Error('unknown expression type '+exp.type+', line '+value.pos.line)
        }
    }
}



export default function(ast, memory)
{
    // create memory for custom functions
    ast.filter(exp => exp.type === "FunctionDeclaration")
       .forEach(exp => {
            if(!memory[exp.name])
            {
                memory[exp.name] = {
                    name: exp.name,
                    variables:{},
                    nextIndex:0,
                    values:[]
                }
            }
       })
    
    memory.memoryArray = []
    Object.values(memory)
    .filter(m => m.name)
    .forEach(m => {
        m.index = memory.memoryArray.length;
        m.memoryArray = memory.memoryArray;
        m.expressionsCount = 0;
        memory.memoryArray.push(m)
    });

    // create object storing the context for each function
    const contexts = {};
    memory.memoryArray.forEach(m => {
        contexts[m.name] = {
            memory: m,
            globalCall
        }
    })
    ast.filter(exp => exp.type === "FunctionDeclaration")
       .forEach(exp => {
           contexts[exp.name].ast = exp
       })

    function globalCall(name, args)
    {
        console.log('global call!', {name, args});
        if(!contexts[name])
        {
            switch(name)
            {
                case 'randInt': 
                    return littleEndianConstant(Math.floor(Math.random()*Math.pow(2, 16)))
                default:
                    throw Error("Unknown function '"+name+"'");
            }
        }
        if(!contexts[name].ast)
        {
            throw Error(`Undefined function '${name}'`);
        }
        const ret = eBody(contexts[name], args);
        return ret;
    }

    console.log({contexts});

    for(const exp of ast)
    {
        if(exp.type === "FunctionDeclaration"
            && exp.name === "main")
        {
            eBody(contexts.main);
        }
    }
}