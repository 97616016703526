

import { h } from 'hyperapp';


export default ({levelName}) => {
    switch(levelName)
    {
        case '1-1': return <div class="fancy-modal">
        <h3>How it works:</h3>
            <p class="message">
                On the right you see the state of the memory
                after your program is executed.
                The editor is where you write C.<br/>
                You see live how the memory is modified, but
                click on VERIFY! to check your program.
            </p>
            <h3>The memory:</h3>
        <p class="message">
            A memory boxe like this <span class="fake-cell">&nbsp;&nbsp;<span class="memory-cell-value">23</span><span class="memory-cell-name">a</span></span> represent
            one Byte of the memory. Here 23 is the value stored (in decimal) and <span class="code-font">a</span> means 
            variable <span class="code-font">a</span> is located here.
        </p>
            <h3>First Rule:</h3>
        <p class="message">
             <span class="fake-cell blue">Blue</span> boxes 
            must contain the value 42
        </p>
            <h3>Disclamer:</h3>
        <p class="message">
            Only a subset of the C syntax is available. 
            Some (a lot of?) features are missing, 
            because that's just a game, and that would be too easy otherwise.
            So if something throws an error, just try something simpler (one can 
            argue that it is part of the game).
            For instance: forget about FOR loops!
        </p>
        <div class="options">
          <button class="fancy-button" onClick={(state) => ({...state, showHelp: false})}>Okay!</button>
        </div>
      </div>

        case '2-1': return <div class="fancy-modal">
            <h3>New Rule:</h3>
        <p class="message">
             <span class="fake-cell pink">Pink</span> boxes 
            must contain the same.
        </p>
            <h3>But...</h3>
        <p class="message">
           <span class="fake-cell readonly">Boxes<span class="readonly-indicator">r</span></span> with
            the <span class="fake-cell readonly"><span class="readonly-indicator">r</span></span> indicator 
            are "read only" (except at their declaration).
        </p>
        <div class="options">
          <button class="fancy-button" onClick={(state) => ({...state, showHelp: false})}>Okay!</button>
        </div>
      </div>

        case '2-5': return <div class="fancy-modal">
            <h3>Be careful...</h3>
        <p class="message">
            When a function is called, the memory locations of already
            terminated functions are considered lost 
            (in practice, as soon as a function returns, its local memory 
            in the stack is considered lost, but in 
            this game, the last called function local memory remains available for the 
            game to check the rules). Here, what happens during the first call
            of <span class="code-font">foo</span> is not persistant.
        </p>
            <h3>Also...</h3>
        <p class="message">
            Some <span class="fake-cell pink blue">Boxes</span> are blue and pink at the same time
        </p>
        <div class="options">
          <button class="fancy-button" onClick={(state) => ({...state, showHelp: false})}>Okay!</button>
        </div>
      </div>
      case '3-1': return <div class="fancy-modal">
      <h3>New Rule:</h3>
        <p class="message">
            If the <span class="fake-cell green-i">Light Green</span> boxes 
            contain the same value <span class="code-font">v</span>, then the <span class="fake-cell green-o">Dark Green</span> boxes
            must also contain <span class="code-font">v</span>. Otherwise the <span class="fake-cell green-o">Dark Green</span> boxes
            must contain <span class="code-font">0</span>.
        </p>
        <div class="options">
            <button class="fancy-button" onClick={(state) => ({...state, showHelp: false})}>Okay!</button>
        </div>
        </div>

    }

    return null;
}